import React, { useState, useEffect } from "react";
import "../../Styles/RemakeOrder.css";
import YMC_Logo_WText_Slogan from "../../Photos/YMC_Logo_WText_Slogan.png"
import TinyExamIcon from "../../Photos/TinyExamIcon.png"
import FirmaOficial from "../../Photos/firma.png"
import PhoneIcon from "../../Photos/PhoneIcon.png"
import EmailIcon from "../../Photos/EmailIcon.png"
import ConfettiExplosion from "react-confetti-explosion";
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import { Modal } from 'flowbite-react';
import examslist from "../../../JSONS/updatedExams.json"
import { useNavigate } from 'react-router-dom';

const RemakeOrder = () => {

    const Navigate = useNavigate();

    const [Wrong, setWrong] = useState(false);
    const [Send, setSend] = useState(false);
    const [PWD,setPWD] = useState("")
    const [Data, setData] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [orderChosen, setOrderChosen] = useState(false);
    const [HowMany, setHowMany] = useState(0);
    const [LoadingImages, setLoadingImages] = useState(false);
    const [LoadingImage1, setLoadingImage1] = useState(false);
    const [LoadingImage2, setLoadingImage2] = useState(false);

    const queryString = window.location.search;
    const OrderId = (new URLSearchParams(queryString)).get('order');

    useEffect(() => {
        if(OrderId === null || OrderId.length < 24) {
            Navigate("/")
        }
    })

    useEffect(() => {
        if(LoadingImage1 && LoadingImage2) {
          setLoadingImages(false)
          setLoadingImage1(false)
          setLoadingImage2(false)
        }
      })

    function PDFstuff() {

        let manyExams = 0
        if(orderChosen === "Imagenología y Procedimientos") {
            manyExams = Data.order.exams.filter((Item) => examslist[Item].emission === "Imagenología" | examslist[Item].emission === "Procedimientos").length
        } else {
            manyExams = Data.order.exams.filter((Item) => examslist[Item].emission === orderChosen).length
        }

        const pdf = new jsPDF();
        if(manyExams > 26) {
            Promise.all([
                html2canvas.toPng(document.getElementById('myPage'), { quality: 0.8 }),
                html2canvas.toPng(document.getElementById('myPage2'), { quality: 0.8 })
            ])
            .then(function([blob1,  blob2]) {
                let imgProps= pdf.getImageProperties(blob1);
                let pdfWidth = pdf.internal.pageSize.getWidth();
                let pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(blob1, 'PNG', 0, 0,pdfWidth, pdfHeight);

                pdf.addPage()
                imgProps= pdf.getImageProperties(blob2);
                pdfWidth = pdf.internal.pageSize.getWidth();
                pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(blob2, 'PNG', 0, 0,pdfWidth, pdfHeight);
                pdf.save("Orden "+orderChosen+".pdf"); 
            });
        } else {
            html2canvas.toPng(document.getElementById('myPage'), { quality: 0.8 })
                .then(function (dataUrl) {
                
                const imgProps= pdf.getImageProperties(dataUrl);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(dataUrl, 'PNG', 0, 0,pdfWidth, pdfHeight);

                pdf.save("Orden "+orderChosen+".pdf"); 
                });
        }
    }

    function tabChange(current,val){
        let tmp = document.getElementById(current)
        tmp.value = tmp.value.replace(/[^A-Za-z0-9]/g,'');
        tmp.value = tmp.value.replace(/[AEIOUaeiou]/g,'');
        tmp.value = tmp.value.toUpperCase()
        let ele = document.querySelectorAll('input');
        if(tmp.value != '') {
            if(ele[val-1].value !== '' && val != 8){
                ele[val].focus()
            }
        } else if(ele[val-1].value === '' && val != 1){
            ele[val-2].focus()
        }
    }

    function verifyPwd() {
        let ele = document.querySelectorAll('input');
        let allinputs = ""
        for(let i=0;i<ele.length;i++) {
            allinputs += ele[i].value
        }
        if(allinputs.length != 8) {
            setWrong(true)
        } else {
            setWrong(false)
            setPWD(allinputs)
            setSend(true)
        }
    }

    async function getOrder() {
        setSend(false)
        setLoading(true)
        var params = {
            object_id: OrderId,
            pwd: PWD
        };
        
        var requestOptions = {
            method: 'POST',
            redirect: 'follow',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(params)
        };
        
        try{
            
            const resp = await fetch("https://ymc-doc-api.azurewebsites.net/retrievemedicalorder", requestOptions)
            if (resp.status !== 200){
                alert("there has been some error");
                return false;
            }
            const data = await resp.json();
            if(data.order) {
                setData(data)
                setLoading(false)
            } else {
                setWrong(true)
                setLoading(false)
                return false
            }
        } catch(error){
            setWrong(true)
            setLoading(false)
            return false
        }
    }

    useEffect(() => {
        if(Send) {
            setSend(false)
            getOrder()
        }
      });

    document.addEventListener("paste", function(e) {
    // if the target is a text input
    if (e.target.type === "text") {
        var data = e.clipboardData.getData('Text');
        // split clipboard text into single characters
        data = data.split('');
        // find all other text inputs
        [].forEach.call(document.querySelectorAll("input[type=text]"), (node, index) => {
        // And set input value to the relative character
        node.value = data[index];
        });
    }
    });
    

    return (
    <>
        {!Data ?
        <div className="RemakeOrder">
            <div className="RemakeOrderPwdInput">
                <div className="RemakeOrderPwdInputTitle">Recuperación de Orden Médica</div>
                <div className="RemakeOrderPwdInputSubtitle">Ingrese la clave de 8 dígitos para recuperar su orden médica.</div>
                <form action="" className="RemakeOrderPwdInputForm mt-5">
                    <input className="RemakeOrderPwdInputOtp" type="text" id="PwdInput1" onKeyUp={() => tabChange("PwdInput1",1)} maxLength={1} />
                    <input className="RemakeOrderPwdInputOtp" type="text" id="PwdInput2" onKeyUp={() => tabChange("PwdInput2",2)} maxLength={1} />
                    <input className="RemakeOrderPwdInputOtp" type="text" id="PwdInput3" onKeyUp={() => tabChange("PwdInput3",3)} maxLength={1} />
                    <input className="RemakeOrderPwdInputOtp" type="text" id="PwdInput4" onKeyUp={() => tabChange("PwdInput4",4)} maxLength={1} />
                    <input className="RemakeOrderPwdInputOtp" type="text" id="PwdInput5" onKeyUp={() => tabChange("PwdInput5",5)} maxLength={1} />
                    <input className="RemakeOrderPwdInputOtp" type="text" id="PwdInput6" onKeyUp={() => tabChange("PwdInput6",6)} maxLength={1} />
                    <input className="RemakeOrderPwdInputOtp" type="text" id="PwdInput7" onKeyUp={() => tabChange("PwdInput7",7)} maxLength={1} />
                    <input className="RemakeOrderPwdInputOtp" type="text" id="PwdInput8" onKeyUp={() => tabChange("PwdInput8",8)} maxLength={1} />
                </form>
                {Wrong ?
                    <div className='RemakeOrderPwdInputVerifyWrongPwd'>Clave incorrecta</div>
                : null }
                {Loading ?
                    <div className='RemakeOrderPwdInputVerifyButton'>Cargando...</div>
                :
                    <div className='RemakeOrderPwdInputVerifyButton' onClick={() => verifyPwd()}>Ingresar</div>
                }
            </div>
        </div>
        : 
        <div className="RemakeOrder">
            <ConfettiExplosion className="Confetti" />
            <h1 className="ThankYouMessage mt-5">
                Tu orden de exámenes ha sido creada con éxito
            </h1>
            <h3 className="ThankYouMessageSub m-0 mb-5">
                Puedes descargar las órdenes con los botones a continuación
            </h3>
            <div className='RemakeOrderPreviewButtons'>
                {Data.order.exams.filter((Item) => examslist[Item].emission === 'Laboratorio').length > 0 ?
                    <button onClick={() => {setOrderChosen('Laboratorio'); setOpenModal(true); setLoadingImages(true); setHowMany(Data.order.exams.filter((Item) => examslist[Item].emission === 'Laboratorio').length);}} className='RemakeOrderPreviewButton rounded-pill'>Ver Orden de Exámenes <br/> Laboratorio</button>
                : null }
                {Data.order.exams.filter((Item) => examslist[Item].emission === 'Imagenología' | examslist[Item].emission === 'Procedimientos').length > 0 ?
                    <button onClick={() => {setOrderChosen('Imagenología y Procedimientos'); setOpenModal(true); setLoadingImages(true); setHowMany(Data.order.exams.filter((Item) => examslist[Item].emission === 'Imagenología' | examslist[Item].emission === 'Procedimientos').length);}} className='RemakeOrderPreviewButton rounded-pill'>Ver Orden de Exámenes <br/> Imagenología / Procedimientos</button>
                : null }
                {Data.order.exams.filter((Item) => examslist[Item].emission === 'Derivaciones').length > 0 ?
                    <button onClick={() => {setOrderChosen('Derivaciones'); setOpenModal(true); setLoadingImages(true); setHowMany(Data.order.exams.filter((Item) => examslist[Item].emission === 'Derivaciones').length);}} className='RemakeOrderPreviewButton rounded-pill'>Ver Orden de Exámenes <br/> Derivaciones</button>
                : null }
            </div>
            <Modal dismissible show={openModal} position="center" className='RemakeOrderPreviewModal'>
                <Modal.Body className='RemakeOrderPreviewModalBody'>
                <div className='RemakeOrderPreviewModalHeaderTitle'>Preview Orden Médica {orderChosen}</div>
                <div className='RemakeOrderPreviewModalHeaderButtons'>
                    {LoadingImages ? 
                    <div className="RemakeOrderPreviewModalDownloadButton">Cargando...</div>
                    :
                    <div className="RemakeOrderPreviewModalDownloadButton" onClick={() => PDFstuff()}>DESCARGAR</div>
}
                    <div className="RemakeOrderPreviewModalCloseButton" onClick={() => setOpenModal(false)}>CERRAR</div>
                </div>
                <div className="RemakeOrderPreviewModalPDFContainer">
                    <div id="myPage" className="RemakeOrderPreviewModalPDF">
                        <div className="RemakeOrderPreviewModalPDFUpperRow">
                            <div className="RemakeOrderPreviewModalPDFUpperLeftSide">
                                <img className="RemakeOrderPreviewModalPDFUpperLeftLogo" alt='' src={YMC_Logo_WText_Slogan} onLoad={() => setLoadingImage1(true)}/>
                            </div>
                            <div className="RemakeOrderPreviewModalPDFUpperRightTexts">
                               {/* <div className="RemakeOrderPreviewModalPDFUpperUpperRightText1">N° de Orden: {OrderId}</div> */}
                                <div className="RemakeOrderPreviewModalPDFUpperUpperRightOrderText">Orden de {orderChosen}</div>
                                <div className="RemakeOrderPreviewModalPDFUpperUpperRightText1">Fecha de emisión</div>
                                <div className="RemakeOrderPreviewModalPDFUpperUpperRightText2">{(new Intl.DateTimeFormat(['ban', 'id']).format(new Date(Data.order.date))).toString()}</div>
                            </div>
                        </div>
                        <div className="RemakeOrderPreviewModalPDFPatientData">
                            <span className="RemakeOrderPreviewModalPDFPatientDataTitle">Paciente</span>
                            <div className="RemakeOrderPreviewModalPDFPatientDataName">
                                Nombre del paciente: {Data.user.name}
                            </div>
                            <div className="RemakeOrderPreviewModalPDFPatientDataSecondRow">
                                <div className="RemakeOrderPreviewModalPDFPatientDataRut">
                                    Rut: {Data.user.rut}
                                </div>
                                <div className="RemakeOrderPreviewModalPDFPatientDataBorn">
                                    Edad: {Data.order.age}
                                </div>
                                <div className="RemakeOrderPreviewModalPDFPatientDataSex">
                                    Sexo: {Data.user.gender === "M" ? 'Hombre' : 'Mujer'}
                                </div>
                            </div>
                        </div>
                        {orderChosen === "Imagenología y Procedimientos" ? 
                        <div className="RemakeOrderPreviewModalPDFExamsData">
                            <div className="RemakeOrderPreviewModalPDFExamsDataUpperFlexDiv">
                                <span className="RemakeOrderPreviewModalPDFExamsDataTitle">Exámenes</span>
                                <span className="RemakeOrderPreviewModalPDFExamsDataTitle">({Data.order.exams.filter((Item) => examslist[Item].emission === "Imagenología" | examslist[Item].emission === "Procedimientos").length})</span>
                            </div>
                            <div className="RemakeOrderPreviewModalPDFExamsDataLowerFlexDiv">
                                {[...Array(Data.order.exams.filter((Item) => examslist[Item].emission === "Imagenología" | examslist[Item].emission === "Procedimientos").length).keys()].map((object) => {
        return (
                                <div className="RemakeOrderPreviewModalPDFExamsDataOneExam" key={object}>
                                {object < 26 ?
                                <div>
                                    <div className="RemakeOrderPreviewModalPDFExamsDataExamName">
                                    <img className="RemakeOrderPreviewModalPDFExamsDataExamIcon" alt='' src={TinyExamIcon} />
                                    &nbsp; {examslist[Data.order.exams.filter((Item) => examslist[Item].emission === "Imagenología" | examslist[Item].emission === "Procedimientos")[object]].name}</div>
                                    <div className="RemakeOrderPreviewModalPDFExamsDataExamPreparation">{examslist[Data.order.exams.filter((Item) => examslist[Item].emission === "Imagenología" | examslist[Item].emission === "Procedimientos")[object]].prep} </div>
                                </div>
                                : null}
                                </div>
                                )})}
                            </div>
                        </div>
                        :
                        <div className="RemakeOrderPreviewModalPDFExamsData">
                            <div className="RemakeOrderPreviewModalPDFExamsDataUpperFlexDiv">
                                <span className="RemakeOrderPreviewModalPDFExamsDataTitle">{orderChosen === "Derivaciones" ? "Derivaciones" : "Exámenes"}</span>
                                <span className="RemakeOrderPreviewModalPDFExamsDataTitle">({Data.order.exams.filter((Item) => examslist[Item].emission === orderChosen).length})</span>
                            </div>
                            <div className="RemakeOrderPreviewModalPDFExamsDataLowerFlexDiv">
                                {[...Array(Data.order.exams.filter((Item) => examslist[Item].emission === orderChosen).length).keys()].map((object) => {
        return (
                                <div className="RemakeOrderPreviewModalPDFExamsDataOneExam" key={object}>
                                {object < 26 ?
                                <div>
                                    <div className="RemakeOrderPreviewModalPDFExamsDataExamName">
                                    <img className="RemakeOrderPreviewModalPDFExamsDataExamIcon" alt='' src={TinyExamIcon} />
                                    &nbsp; {examslist[Data.order.exams.filter((Item) => examslist[Item].emission === orderChosen)[object]].name}</div>
                                    <div className="RemakeOrderPreviewModalPDFExamsDataExamPreparation">{examslist[Data.order.exams.filter((Item) => examslist[Item].emission === orderChosen)[object]].prep} </div>
                                </div>
                                : null}
                                </div>
                                )})}
                            </div>
                        </div>
}
                        <div className="RemakeOrderPreviewModalPDFSignatureDiv">
                            <img className="RemakeOrderPreviewModalPDFSignatureImage" alt='' src={FirmaOficial} onLoad={() => setLoadingImage2(true)}/>
                            <div className="OrderConfirmedPreviewModalPDFDoctorName" >Jorge Caro Días</div>
                            <div className="OrderConfirmedPreviewModalPDFDoctorName" >Médico-Cirujano</div>
                            <div className="OrderConfirmedPreviewModalPDFDoctorName" >RUT: 15.946.308-7&emsp;RCM: 27978-1</div>
                        </div>
                        <div className="RemakeOrderPreviewModalPDFContactDiv">
                            <div className="RemakeOrderPreviewModalPDFContactText">En caso de dudas o consultas, contáctanos</div>
                            <div className="RemakeOrderPreviewModalPDFContactFlex">
                                <div className="RemakeOrderPreviewModalPDFContactLeft">
                                    <img className="RemakeOrderPreviewModalPDFContactIcon" alt='' src={PhoneIcon} />
                                    <span className="RemakeOrderPreviewModalPDFContactLowerText"> &nbsp; +56 9 7127 3898 </span>
                                </div>
                                <div className="RemakeOrderPreviewModalPDFContactRight">
                                    <img className="RemakeOrderPreviewModalPDFContactIcon" alt='' src={EmailIcon} />
                                    <span className="RemakeOrderPreviewModalPDFContactLowerText"> &nbsp; contacto@yomecontrolo.cl </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {HowMany > 26 ? 
                <div className="RemakeOrderPreviewModalPDFContainer">
                    <div id="myPage2" className="RemakeOrderPreviewModalPDF">
                        <div className="RemakeOrderPreviewModalPDFUpperRow">
                        <div className="RemakeOrderPreviewModalPDFUpperLeftSide">
                            <img className="RemakeOrderPreviewModalPDFUpperLeftLogo" alt='' src={YMC_Logo_WText_Slogan}/>
                        </div>
                        <div className="RemakeOrderPreviewModalPDFUpperRightTexts">
                            <div className="RemakeOrderPreviewModalPDFUpperUpperRightOrderText">Orden de {orderChosen}</div>
                            <div className="RemakeOrderPreviewModalPDFUpperUpperRightText1">Fecha de emisión</div>
                            <div className="RemakeOrderPreviewModalPDFUpperUpperRightText2">24/3/2024</div>
                        </div>
                        </div>
                        <div className="RemakeOrderPreviewModalPDFPatientData">
                        <span className="RemakeOrderPreviewModalPDFPatientDataTitle">Paciente</span>
                        <div className="RemakeOrderPreviewModalPDFPatientDataName">
                            Nombre del paciente: {Data.user.name}
                        </div>
                        <div className="RemakeOrderPreviewModalPDFPatientDataSecondRow">
                            <div className="RemakeOrderPreviewModalPDFPatientDataRut">
                            Rut: {Data.user.rut}
                            </div>
                            <div className="RemakeOrderPreviewModalPDFPatientDataBorn">
                            Edad: {Data.order.age}
                            </div>
                            <div className="RemakeOrderPreviewModalPDFPatientDataSex">
                            Sexo: {Data.user.gender === "M" ? 'Hombre' : 'Mujer'}
                            </div>
                        </div>
                    </div>
                    {orderChosen === "Imagenología y Procedimientos" ? 
                    <div className="RemakeOrderPreviewModalPDFExamsData">
                        <div className="RemakeOrderPreviewModalPDFExamsDataUpperFlexDiv">
                            <span className="RemakeOrderPreviewModalPDFExamsDataTitle">Exámenes</span>
                            <span className="RemakeOrderPreviewModalPDFExamsDataTitle">({Data.order.exams.filter((Item) => examslist[Item].emission === "Imagenología" | examslist[Item].emission === "Procedimientos").length})</span>
                        </div>
                        <div className="RemakeOrderPreviewModalPDFExamsDataLowerFlexDiv">
                            {[...Array(Data.order.exams.filter((Item) => examslist[Item].emission === "Imagenología" | examslist[Item].emission === "Procedimientos").length).keys()].map((object) => {
    return (
                            <div className="RemakeOrderPreviewModalPDFExamsDataOneExam" key={object}>
                            {object > 25 ?
                            <div>
                                <div className="RemakeOrderPreviewModalPDFExamsDataExamName">
                                <img className="RemakeOrderPreviewModalPDFExamsDataExamIcon" alt='' src={TinyExamIcon} />
                                &nbsp; {examslist[Data.order.exams.filter((Item) => examslist[Item].emission === "Imagenología" | examslist[Item].emission === "Procedimientos")[object]].name}</div>
                                <div className="RemakeOrderPreviewModalPDFExamsDataExamPreparation">{examslist[Data.order.exams.filter((Item) => examslist[Item].emission === "Imagenología" | examslist[Item].emission === "Procedimientos")[object]].prep} </div>
                            </div>
                            : null}
                            </div>
                            )})}
                        </div>
                    </div>
                    :
                    <div className="RemakeOrderPreviewModalPDFExamsData">
                        <div className="RemakeOrderPreviewModalPDFExamsDataUpperFlexDiv">
                            <span className="RemakeOrderPreviewModalPDFExamsDataTitle">{orderChosen === "Derivaciones" ? "Derivaciones" : "Exámenes"}</span>
                            <span className="RemakeOrderPreviewModalPDFExamsDataTitle">({Data.order.exams.filter((Item) => examslist[Item].emission === orderChosen).length})</span>
                        </div>
                        <div className="RemakeOrderPreviewModalPDFExamsDataLowerFlexDiv">
                            {[...Array(Data.order.exams.filter((Item) => examslist[Item].emission === orderChosen).length).keys()].map((object) => {
        return (
                            <div className="RemakeOrderPreviewModalPDFExamsDataOneExam" key={object}>
                            {object > 25 ?
                            <div>
                            <div className="RemakeOrderPreviewModalPDFExamsDataExamName">
                                <img className="RemakeOrderPreviewModalPDFExamsDataExamIcon" alt='' src={TinyExamIcon} />
                                &nbsp; {examslist[Data.order.exams.filter((Item) => examslist[Item].emission === orderChosen)[object]].name}</div>
                            <div className="RemakeOrderPreviewModalPDFExamsDataExamPreparation">{examslist[Data.order.exams.filter((Item) => examslist[Item].emission === orderChosen)[object]].prep} </div>
                            </div>
                            : null}
                            </div>
        )})}
                        </div>
                    </div>
}
                    <div className="RemakeOrderPreviewModalPDFSignatureDiv">
                        <img className="RemakeOrderPreviewModalPDFSignatureImage" alt='' src={FirmaOficial}/>
                        <div className="OrderConfirmedPreviewModalPDFDoctorName" >Jorge Caro Días</div>
                        <div className="OrderConfirmedPreviewModalPDFDoctorName" >Médico-Cirujano</div>
                        <div className="OrderConfirmedPreviewModalPDFDoctorName" >RUT: 15.946.308-7&emsp;RCM: 27978-1</div>
                    </div>
                    <div className="RemakeOrderPreviewModalPDFContactDiv">
                        <div className="RemakeOrderPreviewModalPDFContactText">En caso de dudas o consultas, contáctanos</div>
                        <div className="RemakeOrderPreviewModalPDFContactFlex">
                            <div className="RemakeOrderPreviewModalPDFContactLeft">
                                <img className="RemakeOrderPreviewModalPDFContactIcon" alt='' src={PhoneIcon} />
                                <span className="RemakeOrderPreviewModalPDFContactLowerText"> &nbsp; +56 9 7127 3898 </span>
                            </div>
                            <div className="RemakeOrderPreviewModalPDFContactRight">
                                <img className="RemakeOrderPreviewModalPDFContactIcon" alt='' src={EmailIcon} />
                                <span className="RemakeOrderPreviewModalPDFContactLowerText"> &nbsp; contacto@yomecontrolo.cl </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : null
            }
            </Modal.Body>
                <Modal.Footer>
                <div className="RemakeOrderPreviewModalFooter">
                    <div className="RemakeOrderPreviewModalFooterCloseButton" onClick={() => setOpenModal(false)}>CERRAR</div>
                </div>
                </Modal.Footer>
            </Modal>
            </div>
}
    </>
  );
};

export default RemakeOrder;